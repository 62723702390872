<template>
  <div class="Resigter">
    <div class="register_box">
      <div class="register_title">
        <div class="pull-left">
          <div class="r-title">
            <i class="el-icon-s-custom"></i>
            用户注册
          </div>
          <div class="r-tip">
            我已经注册，立即
            <span @click="JumpLogin">登录</span>
          </div>
        </div>
        <div class="pull-right">
          <el-steps :active="active" finish-status="success" simple>
            <el-step title="添加手机号"></el-step>
            <el-step title="填写用户信息"></el-step>
          </el-steps>
        </div>
      </div>
      <el-form
        v-show="active == 1"
        :model="MobileNumberForm"
        label-width="100px"
        label-position="top"
        :rules="MobileNumberFormRules"
        ref="MobileNumberForm"
        class="Add_mobile_number"
      >
        <el-form-item label="手机号" prop="phone_num">
          <el-input
            placeholder="手机号"
            clearable
            v-model="MobileNumberForm.phone_num"
            prefix-icon="el-icon-user"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="图形验证码" prop="graphic_code">
          <el-input
            style="position: relative"
            placeholder="图形验证码"
            prefix-icon="el-icon-lock"
            v-model="MobileNumberForm.graphic_code"
          >
          </el-input>
          <el-image
            @click="getSendGraphic"
            style="
              width: 120px;
              height: 48px;
              position: absolute;
              right: 1px;
              top: 1px;
              border-radius: 4px;
              cursor: pointer;
            "
            :src="VerificationCodeImgUrl"
            fit="scale-down"
          >
          </el-image>
        </el-form-item>
        <el-form-item label="短信验证码" prop="verification_code">
          <el-input
            style="position: relative"
            placeholder="短信验证码"
            prefix-icon="el-icon-lock"
            v-model="MobileNumberForm.verification_code"
          >
          </el-input>
          <el-button
            @click="GetPhoneVerification"
            :disabled="!disabledCodeBtn"
            style="
              width: 120px;
              height: 48px;
              position: absolute;
              right: 1px;
              top: 1px;
              border-radius: 4px;
              cursor: pointer;
            "
            type="success"
            >{{ codeText }}
          </el-button>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button type="primary" @click="nextStep(1)">下一步</el-button>
        </el-form-item>
      </el-form>

      <el-form
        v-show="active == 2"
        label-width="80px"
        :model="registerForm"
        :rules="registerFormRules"
        ref="registerForm"
        class="registerForm"
      >
        <el-form-item label="密码" style="width: 48%" prop="password">
          <el-input
            type="password"
            show-password
            clearable
            placeholder="密码"
            v-model="registerForm.password"
            prefix-icon="el-icon-lock"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="姓名"
          style="width: 48%; margin-left: 2%"
          prop="realname"
        >
          <el-input
            clearable
            placeholder="请输入姓名"
            v-model="registerForm.realname"
          ></el-input>
        </el-form-item>
        <el-form-item label="昵称" style="width: 48%" prop="nickname">
          <el-input
            clearable
            placeholder="请输入昵称"
            v-model="registerForm.nickname"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="学号"
          style="width: 48%; margin-left: 2%"
          prop="student_num"
        >
          <el-input
            clearable
            placeholder="请输入学号"
            v-model="registerForm.student_num"
          ></el-input>
        </el-form-item>
        <el-form-item label="学校" style="width: 48%" prop="school_name">
          <el-input
            clearable
            placeholder="请输入学校"
            v-model="registerForm.school_name"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="专业"
          style="width: 48%; margin-left: 2%"
          prop="specialty"
        >
          <el-input
            clearable
            placeholder="请输入专业"
            v-model="registerForm.specialty"
          ></el-input>
        </el-form-item>
        <el-form-item label="年级" style="width: 48%" prop="grade">
          <!-- <el-input clearable placeholder="年级" v-model="registerForm.grade"></el-input> -->
          <el-select
            v-model="registerForm.grade"
            clearable
            placeholder="请选择年级"
            style="width: 100%"
          >
            <el-option
              v-for="item in gradeList"
              :key="item.value"
              :label="item.dictLabel"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="学历"
          style="width: 48%; margin-left: 2%"
          prop="education_background"
        >
          <el-select
            style="width: 100%"
            placeholder="请选择真实学历"
            v-model="registerForm.education_background"
          >
            <el-option
              v-for="item in educationBackgroundList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="指导老师"
          style="width: 48%"
          prop="faculty_adviser"
        >
          <el-input
            clearable
            placeholder="请输入指导老师（如未指导老师请填写‘无’）"
            v-model="registerForm.faculty_adviser"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Email"
          style="width: 48%; margin-left: 2%"
          prop="email"
        >
          <el-input
            clearable
            placeholder="请输入Email"
            v-model="registerForm.email"
          ></el-input>
        </el-form-item>
        <div style="width: 100%; text-align: center; margin: 10px 0">
          <el-button type="primary" @click="nextStep(2)">上一步</el-button>
          <el-button type="success" @click="RegisterNow">立即注册</el-button>
        </div>
        <el-row
          style="
            margin: 0 auto;
            text-align: center;
            font-size: 14px;
            color: #606266;
          "
        >
          <el-col>
            <el-checkbox v-model="checked">同意遵守</el-checkbox
            ><span @click="JumpUserAgreement" style="color: rgb(0, 100, 200)"
              >《用户注册协议》</span
            >
            <br />
            新版《用户协议》已更新，请您及时查看
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import { send_graphic, phone_verification, register } from "../api/login";
export default {
  name: "Resigter",
  data() {
    return {
      active: 1,
      VerificationCodeImgUrl: "", //验证码图片
      // 注册表单
      // RegisterForm: {
      //     register_dict: {
      //         username: "18408234313",
      //         password: "123",
      //         realname: "普通用户",
      //         student_num: "30033598",
      //         school_name: "东软学院",
      //         faculty_adviser: "暂无",
      //         email: "1140356273@qq.com",
      //         specialty: "计算机",
      //         education_background: "本科",
      //         nickname: "羽修",
      //         grade: "2017",
      //     },
      //     verification_code: 984567,
      // },
      disabledCodeBtn: true,
      codeText: "获取验证码",
      MobileNumberForm: {
        phone_num: "",
        graphic_code: "",
        verification_code: "",
      },
      MobileNumberFormRules: {
        phone_num: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        graphic_code: [
          {
            required: true,
            message: "请输入图形验证码",
            trigger: "blur",
          },
        ],
        verification_code: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur",
          },
        ],
      },
      registerForm: {
        password: "",
        realname: "",
        student_num: "",
        school_name: "",
        faculty_adviser: "",
        email: "",
        specialty: "",
        education_background: "",
        nickname: "",
        grade: "",
      },
      registerFormRules: {
        password: [
          {
            validator: this.validateContent,
            trigger: "blur",
          },
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        specialty: [
          {
            validator: this.validateContent,
            trigger: "blur",
          },
          { required: true, message: "请输入专业", trigger: "blur" },
        ],
        grade: [{ required: true, message: "请选择年级", trigger: "change" }],
        education_background: [
          { required: true, message: "请输入学历", trigger: "change" },
        ],
        realname: [
          {
            validator: this.validateContent,
            trigger: "blur",
          },
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        nickname: [
          {
            validator: this.validateContent,
            trigger: "blur",
          },
          { required: true, message: "请输入昵称", trigger: "blur" },
        ],
        student_num: [
          {
            validator: this.validateContent,
            trigger: "blur",
          },
          { required: true, message: "请输入学号", trigger: "blur" },
        ],
        school_name: [
          {
            validator: this.validateContent,
            trigger: "blur",
          },
          { required: true, message: "请输入学校", trigger: "blur" },
        ],
        email: [
          {
            required: true,
            message: "请输入真实邮箱",
            trigger: "blur",
          },
          {
            pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
            message: "邮箱格式错误",
          },
        ],
        faculty_adviser: [
          { required: true, message: "请输入指导老师", trigger: "blur" },
        ],
        verification_code: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
      },
      gradeList: [],
      educationBackgroundList: [
        "高中及以下",
        "大专",
        "本科",
        "硕士研究生",
        "博士研究生",
      ],
      checked: false,
    };
  },
  created() {
    // 获取权限
    // if (getToken()) {
    //     this.$router.push({
    //         path: this.redirect || "/"
    //     })
    // }
    this.getNf();
  },
  mounted() {
    this.getSendGraphic();
  },
  methods: {
    validateContent(rule, value, callback) {
        // 使用正则表达式检查输入内容是否为空或只包含空格
        const regExp = /^\s*$/;
        if (!value || regExp.test(value)) {
            callback(new Error('内容不能为空或只包含空格'));
        } else {
            callback();
        }
    },
    JumpUserAgreement() {
      window.open("https://resource.delist.cn/用户注册协议V3.pdf");
    },
    // 显示近10年年级列表
    getNf() {
      var nfOptionsArray = new Array();
      var years = new Date().getFullYear();
      for (let i = years - 9; i <= years; i++) {
        var anOption = {};
        anOption.dictValue = i + "";
        anOption.dictLabel = i + "";
        nfOptionsArray.push(anOption);
      }
      this.gradeList = nfOptionsArray;
    },
    getSendGraphic() {
      send_graphic().then((res) => {
        this.VerificationCodeImgUrl = "data:image/png;base64," + res;
      });
    },
    // 跳转注册
    JumpLogin() {
      this.$router.push({
        path: "/login",
      });
    },
    // 倒计时方法
    countDown(time) {
      if (time === 0) {
        this.disabledCodeBtn = true;
        this.codeText = "获取验证码";
        return;
      } else {
        this.disabledCodeBtn = false;
        this.codeText = "重新发送(" + time + ")";
        time--;
      }
      setTimeout(() => {
        this.countDown(time);
      }, 1000);
    },
    // 获取短信验证码
    GetPhoneVerification() {
      if (!this.MobileNumberForm.phone_num) {
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
        return;
      } else if (!this.MobileNumberForm.graphic_code) {
        this.$message({
          message: "图形验证码不能为空",
          type: "warning",
        });
        return;
      }
      phone_verification({
        phone_num: this.MobileNumberForm.phone_num,
        graphic_code: this.MobileNumberForm.graphic_code,
      })
        .then((res) => {
          if (res.code == 200) {
            this.countDown(60);
            this.$message({
              type: "success",
              message: "已发送短信",
            });
          }
        })
        .catch((err) => {
          // 从新获取验证码
          if (err.code == 10025 && err.msg == "图形码错误或过期") {
            this.getSendGraphic();
          }
        });
    },
    // 下一步
    nextStep(index) {
      if (index == 1) {
        this.$refs["MobileNumberForm"].validate((valid) => {
          if (valid) {
            this.active = 2;
          } else {
            return false;
          }
        });
      } else {
        this.active = 1;
      }
    },
    // 立即注册
    RegisterNow() {
      this.$store.commit("app/CHANGE_LOADING", true);
      this.$refs["registerForm"].validate((valid) => {
        if (valid) {
          if (!this.checked) {
            this.$message({
              type: "danger",
              message: "请阅读并勾选用户协议",
            });
            this.$store.commit("app/CHANGE_LOADING", false);
            return;
          }
          let register_dict = JSON.parse(JSON.stringify(this.registerForm));
          register_dict.username = this.MobileNumberForm.phone_num;
          register({
            register_dict: register_dict,
            verification_code: this.MobileNumberForm.verification_code,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$store.commit("app/CHANGE_LOADING", false);
                this.resetForm("MobileNumberForm");
                this.resetForm("registerForm");
                this.registerForm = {};
                this.MobileNumberForm = {};
                this.JumpLogin();
              } else {
                this.$store.commit("app/CHANGE_LOADING", false);
              }
            })
            .catch(() => {
              this.$store.commit("app/CHANGE_LOADING", false);
            });
        } else {
          this.$store.commit("app/CHANGE_LOADING", false);
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.Resigter {
  box-sizing: border-box;
  width: 100vw;
  height: 600px;
  // background: url('../assets/imgs/bigbg.jpg') no-repeat;
  // background-size: cover;
  // background-color: #2b67ee;
  position: relative;

  .register_box {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    width: 1198px;
    margin: 0px auto;
    background: #fff;
    border: 1px solid #efefef;
    min-height: 550px;

    .register_title {
      box-sizing: border-box;
      width: 100%;
      height: 100px;
      padding: 45px 0 25px;
      border-bottom: 1px solid #eee;
      display: flex;

      .pull-left {
        width: 50%;
        display: flex;

        .r-title {
          font-size: var(--theme-h1-font-size);
          padding-left: 36px;
          line-height: var(--theme-h1-font-size);
          color: var(--theme-title-color);
          display: inline-block;
        }

        .r-tip {
          color: #999;
          margin-left: 10px;
          font-size: var(--theme-txt-font-size);
          line-height: 50px;

          span {
            color: var(--theme-title-color);
            font-weight: 600;
            cursor: pointer;
          }
        }
      }

      .pull-right {
        width: 50%;
      }
    }

    .Add_mobile_number {
      padding: 15px 30px;
      width: 560px;
      margin: 0 auto;
    }

    .registerForm {
      padding: 15px 30px;
      width: 900px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.Add_mobile_number ::v-deep .el-form-item__content {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}

.Add_mobile_number ::v-deep .el-input__inner {
  height: 50px;
}

.registerForm ::v-deep .el-form-item__content {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  margin-left: 0;
}

.registerForm ::v-deep .el-input__inner {
  height: 50px;
}

.registerForm ::v-deep .el-form-item__label {
  line-height: 50px;
  height: 50px;
}
</style>
